import * as React from "react"
import { Link } from "gatsby"


// markup
const NotFoundPage = () => {
  return (
    <h1>a</h1>
  )
}

export default NotFoundPage
